<template>
    <div class="device">
        <el-card>
            <template #header>
                <span>设备管理</span>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-select v-model="merchantid" size="small" style="margin-right:20px;width:100px" clearable v-if="ismerchant != 1" @change="GetStore" >
                        <el-option v-for="item in merchantlist" :key="item" :label="item.Merchant" :value="item.ID"></el-option>
                    </el-select>
                    <el-select v-model="storeid" size="small" style="margin-right:20px;width:100px" :disabled='(merchantid||ismerchant==1)?false:true' clearable>
                        <el-option v-for="item in stores" :key="item" :label="item.Store" :value="item.ID"></el-option>
                    </el-select>
                    <el-select v-model="status" size="small" style="margin-right:20px;width: 100px;">
                        <el-option label="全部" value="0"></el-option>
                        <el-option label="启用" value="1"></el-option>
                        <el-option label="停用" value="2"></el-option>
                    </el-select>
                    <el-input v-model="keyword" size="small" style="width:250px;margin-right:10px" placeholder="请输入设备编号/硬件编码/名称"></el-input>
                    <el-button @click="init" size="small" type="primary">搜索</el-button>
                    <el-button @click="add" size="small" type="success" v-if="ismerchant != 1">添加设备</el-button>
                </div>
                <div>
                    <el-table :data="tabledata" border style="width: 100%; margin: 1em 0;"
                                v-loading="load"
                                size="small"
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中...">
                        <el-table-column label="设备编码" prop="DeviceCode" width="80px"></el-table-column>
                        <el-table-column label='硬件编码' prop="HardwareNo" width="150px"></el-table-column>
                        <el-table-column label="设备名称" prop="Device" ></el-table-column>
                        <el-table-column label="商户名称" prop="Merchant" ></el-table-column>
                        <el-table-column label="所在店铺" prop="Store" >
                            <template #default='scope'>
                                <span v-if="scope.row.Store">{{scope.row.Store}}</span>
                                <span v-else> / </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备价格">
                            <template #default='scope'>
                                <div v-if="scope.row.DeviceRule">
                                    <span v-for="item in JSON.parse(scope.row.DeviceRule)" :key="item">{{item.time}}{{item.type == 1?'分钟':'小时'}}/{{item.price}}元/{{item.people}}人<br></span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备状态" align="center" width="100px;">
                            <template #default='scope'>
                                <span v-if="scope.row.Status == 1">启用</span>
                                <span v-if="scope.row.Status == 2" style="color:#ff0000">停用</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="激活状态" align="center" width="100px;">
                            <template #default='scope'>
                                <span v-if="scope.row.ActiveStatus == 0" style="color:#ff0000">等待设备激活</span>
                                <span v-if="scope.row.ActiveStatus == 1">已激活</span>
                            </template>
                        </el-table-column>
                        
    
                        <el-table-column label="操作" >
                            <template #default='scope'>
                                <!--<el-button @click="Active(scope.row.DeviceCode,scope.row.HardwareNo)" size="small" type="text" v-if="scope.row.ActiveStatus == 0">激活</el-button>-->
                                <el-button @click="Unbind(scope.row.ID,scope.$index)" size="small" type="text" v-if="scope.row.ActiveStatus == 1">解绑</el-button>
                                <el-button @click="Disable(scope.row.ID)" size="small" type="text" v-if="scope.row.Status == 1">停用</el-button>
                                <el-button @click="Enable(scope.row.ID)" size="small" type="text" v-if="scope.row.Status == 2">启用</el-button>
                                
                                <el-button @click="Edit(scope.row.ID)" size="small" type="text">编辑</el-button>
                                <el-button @click="Log(scope.row.ID)" size="small" type="text">日志</el-button>
                                <el-button @click="Copy(scope.row.ID)" size="small" type="text">复制链接</el-button>


                                <el-button size="small" type="text" @click="lookwx(scope.row.ID)">查看微信端二维码</el-button>

                                <el-button size="small" v-if="!scope.row.Android" type="text" @click="Android(scope.row.ID)">生成安卓端数据</el-button>

                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                
                                style="text-align:center"></el-pagination>
                </div>
            </div>
        </el-card>


        <el-dialog v-model="adddevice" title="设备信息" center>

        <el-form :model="deviceinfo" label-width="150px" class="deviceinfo" ref="deviceinfo" :rules="rule">
            <el-form-item label="归属商户" prop="MerchantID" required v-if="ismerchant != 1">
                <el-select v-model="deviceinfo.MerchantID" size="small" @change="GetStore">
                    <el-option v-for="item in merchantlist" :key="item" :label="item.Merchant" :value="item.ID"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="设备名称" prop="Device" required>

                <el-input v-model="deviceinfo.Device" size="small" :disabled="ismerchant!= 1?false:true"></el-input>
            </el-form-item>
            <el-form-item label="设备编号" prop="DeviceCode" required >
                <el-input-number v-model="deviceinfo.DeviceCode" size="small" :controls='false' :disabled="ismerchant!= 1?false:true"></el-input-number>

                <br>
                <el-tag type="primary" size="small">大于或等于100的整数</el-tag>
            </el-form-item>
            <el-form-item label="设备描述">
                <el-input v-model="deviceinfo.DeviceInfo" type="textarea" :rows="4" size="small"></el-input>
            </el-form-item>
            
            <el-form-item label="所属店铺" prop="StoreID" required >
                <div v-if="ismerchant != 1">
                    <el-select v-model="deviceinfo.StoreID" size="small" :disabled="deviceinfo.MerchantID?false:true">
                        <el-option v-for="item in stores" :key="item" :label="item.Store" :value="item.ID"></el-option>
                    </el-select>
                </div>
            </el-form-item>
            <el-form-item label="硬件编码" prop="HardwareNo" required>

                <el-input v-model="deviceinfo.HardwareNo" size="small" :disabled="ismerchant!= 1?false:true"></el-input>

            </el-form-item>
            <el-form-item label="游戏推荐" prop="Recommend">
                <div style="display: flex; flex-direction: row">
                <draggable
                    class="vue-draggable"
                    v-model="deviceinfo.GameMap"
                    @start="onDragStart"
                    @end="onDragEnd"
                    item-key="index"
                >
                    <template #item="{ element, index }">
                    <div class="draggable-item">
                        <el-image
                        :src="element.url"
                        :preview-src-list="[element.url]"
                        ></el-image>
                        <div class="shadow" @click="onRemoveHandler(index)">
                        <i class="el-icon-delete"></i>
                        </div>
                    </div>
                    </template>
                </draggable>
                <el-upload
                    class="avatar-uploader"
                    :action="upload_url"
                    list-type="picture-card"
                    :on-success="UploadSuccess"
                    :on-change="UploadChange"
                    :on-error="UploadError"
                    :on-remove="RemovePic"
                    :on-preview="Preview"
                    accept=".jpg,.jpeg,.png"
                    :show-file-list="false"
                    multiple
                    :file-list="FileList"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
                <!--
                            <template #tip>
                                <div class="el-upload__tip">只能上传 jpg/png 文件</div>
                            </template>-->
                </div>
                <el-dialog v-model="dialogVisible">
                <img style="width: 100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
            </el-form-item>
            <el-form-item label="设备价格" prop="Rules" required>
                <div v-for="item,index in deviceinfo.Rules" :key="item">
                    <el-input-number :controls='false' v-model="item.time" style="width:80px;margin-right:5px;" size="small"></el-input-number>
                    <el-select v-model="item.type" style="width:80px;margin-right:5px;" size="small" >
                        <el-option label="分钟" value="1"></el-option>
                        <!-- <el-option label="小时" value="2"></el-option> -->
                    </el-select>
                    <el-input-number :controls='false' v-model="item.price" style="width:80px;margin-right:5px;" size="small"></el-input-number>元 /
                    <el-input-number :controls='false' v-model="item.people" style="width:80px;margin-right:5px;" size="small"></el-input-number>人 
                    <el-button @click="addone" type="success" size="small" v-if="index == 0">添加</el-button>
                    <el-button @click="del(index)" type="danger" size="small" v-if="index !== 0">删除</el-button>
                </div>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <template #footer>
            <el-button @click="save" type="primary">确定</el-button>
            <el-button @click="cancel">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="devicelog" title="设备日志">
        <el-table size="small" :data="logdata" border style="width: 100%; margin: 1em 0;">
            <el-table-column label="操作时间">
                <template #default='scope'>
                    {{formatDate(scope.row.AddDTime)}}
                </template>
            </el-table-column>
            <el-table-column label="操作人" prop="OpName"></el-table-column>
            <el-table-column label="备注" prop="Remark"></el-table-column>
        </el-table>
    </el-dialog>


    <el-dialog v-model="lwx" width="30%" title="微信二维码" center>
        <div id="qrcode" style="display:flex;justify-content:center;" ref="qrcode"></div>
        <template #footer>
        <span class="dialog-footer">
            <el-button @click="lwx = false">关闭</el-button>
        </span>
        </template>
    </el-dialog>
    </div>
</template>
<script>
import QRCode from "qrcodejs2-fix"
import Cookies from 'js-cookie'
import qs from "qs"
import constant from "@/constant"
import { nextTick } from 'vue'
import draggable from "vuedraggable";
export default {
    data(){
        return {
            upload_url: constant.upload_temp_img_url,
            dialogImageUrl: "",
            dialogVisible: false,
            drag:false,
            lwx:false,
            opid:'',
            roleid:"",
            devicelog:false,
            tabledata:[],
            load:false,
            curpage:1,
            pagesize:20,
            stores:[],
            merchantlist:[],
            merchantid:'',
            storeid:'',
            status:'',
            keyword:'',
            adddevice:false,
            deviceinfo:{
                ID:'',
                Device:'',
                DeviceCode:0,
                DeviceInfo:'',
                StoreID:"",
                HardwareNo:'',
                MerchantID:'',
                GameMap:[],
                Rules:[
                    {
                        time:'',
                        type:'1',
                        price:'',
                        people:''
                    }
                ]
            },
            rule:{
                MerchantID:[{
                    required:true,
                    message:'请选择商户',
                    trigger:'blur'
                }],
                Device:[{
                    required:true,
                    message:'请填写设备名称',
                    trigger:'blur'
                }],
                DeviceCode:[{
                    required:true,
                    message:'请填写设备编号',
                    trigger:'blur'
                }],
                StoreID:[{
                    required:true,
                    message:'请选择所属店铺',
                    trigger:'blur'
                }],
                HardwareNo:[{
                    required:true,
                    message:'请填写硬件编码',
                    trigger:'blur'
                }],
            }
        }
    },
    methods:{
        UploadSuccess(response, file) {
            console.log(response);
            console.log(file);
            if (response.error == "0") {
                var uid = file.uid;
                var name = file.name;
                var url = "";
                if (file.response && file.response == 0) url = file.response.url;
                else url = response.url;
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.deviceinfo.GameMap.push(obj);
            }
            console.log(this.deviceinfo.GameMap);
        },

        RemovePic(file, filelist) {
            console.log(file);
            console.log(filelist);
            this.deviceinfo.GameMap = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]["uid"];
                var name = filelist[i]["name"];
                var url = filelist[i]["url"];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.deviceinfo.GameMap.push(obj);
            }
        },
        Preview(file) {
            console.log(file);
            if (file.response) {
                if (file.response.error == 0) {
                this.dialogImageUrl = file.response.url;
                console.log(this.dialogImageUrl);
                }
            } else {
                this.dialogImageUrl = file.url;
            }

            this.dialogVisible = true;
        },
        onDragStart() {
            this.drag = true;
        },
        onDragEnd() {
            this.drag = false;
            console.log(this.deviceinfo.GameMap);
        },
        onRemoveHandler(index) {
            this.deviceinfo.GameMap.splice(index, 1);
        },
        Android(id){
            let link = "alipays://platformapi/startapp?appId=2021001199601696&page=/pages/expshop/device/device&query=did%3D"+id;
            let text = "https://tiyan.xianniuzu.com/admin/wxrequest.php?id="+id+"&path=/pages/first/first&type=1";
            this.axios.get(constant.Android,{
                headers:{
                    "content-type":"aplication/json"
                },
                params:{
                    id:id,
                    link:link,
                    text:text
                }
            }).then((response)=>{
                console.log(response)
                if(response.data.code == 1){
                    this.$message.success("获取成功");
                }else{
                    this.$message.error("获取失败");
                }
            })
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        Copy(id){
            let link = "alipays://platformapi/startapp?appId=2021001199601696&page=/pages/expshop/device/device&query=did%3D"+id;
            const input = document.createElement('input');
            input.value = link;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            
            this.$message.warning("链接已经复制到剪切板");
            document.body.removeChild(input);
        },
        Log(id){
            this.axios.get(constant.devicelog,{
                headers:{
                    "content-type":"aplication/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.devicelog = true;
                this.logdata = response.data;
            });
        },
        del(index){
            
            this.deviceinfo.Rules.splice(index,1);
            console.log(this.deviceinfo.Rules);
        },


        lookwx(id){
            console.log(id)
            this.lwx = true
            console.log(this.$refs)
            //let qrcodediv = document.getElementById("qrcode");
            let text = "https://tiyan.xianniuzu.com/admin/wxrequest.php?id="+id+"&path=/pages/first/first&type=1"
            nextTick(()=>{
                document.getElementById("qrcode").innerHTML=""
                new QRCode(document.getElementById("qrcode"),{
                    text:text,
                    width:200,
                    height:200,
                    colorDark:"#000000",
                    colorLight:"#ffffff"
                })
            })
        },


        GetStore(merchantid){
            console.log(merchantid);
            //this.deviceinfo.StoreID = "";
            this.axios.get(constant.allstore,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    merchantid:merchantid
                }
            }).then((response)=>{
                console.log(response.data);
                this.stores = response.data;
            });
        },
        add(){
            this.deviceinfo = {
                ID:'',
                Device:'',
                DeviceCode:0,
                DeviceInfo:'',
                GameMap:[],
                HardwareNo:'',
                MerchantID:'',
                Rules:[
                    {
                        time:'',
                        type:'1',
                        price:'',
                        people:''
                    }
                ]
            };
            this.adddevice = true;
        },
        init(){
            this.axios.get(constant.devicelist,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    keyword:this.keyword,
                    status:this.status,
                    merchantid:this.merchantid,
                    storeid:this.storeid,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        },
        cancel(){
            this.adddevice = false;
        },
        save(){
            //let reg = /^\d{32}$/;
            console.log(this.deviceinfo.DeviceCode);
            
            if(this.deviceinfo.DeviceCode < 100){
                this.$message.error("设备编号必须是大于或者等于100的整数");
                return false;
            }
            this.$refs['deviceinfo'].validate((valid)=>{
                this.flag = true;
                if(valid){
                    for(let i = 0;i<this.deviceinfo.Rules.length;i++){
                        let cur = this.deviceinfo.Rules[i];
                        if(!cur.time || !cur.price || !cur.people){
                            this.$message.error("请补充好设备价格");
                            this.flag = false;
                        }
                    }
                    if(this.flag){
                        console.log(this.deviceinfo);
                        this.axios.post(constant.savedevice,qs.stringify(this.deviceinfo),{
                            headers:{
                                "content-type":"application/x-www-form-urlencoded"
                            },
                            params:{
                                opid:this.opid,
                                role:this.role,
                                ismerchant:this.ismerchant,
                                mid:this.mid
                            }
                        }).then((response)=>{
                            console.log(response.data);
                            if(response.data == 'OK'){
                                this.$message.success("操作成功");
                                this.$router.go(0);
                            }else if(response.data == 'REPEAT'){
                                this.$message.error("硬件编码或者设备编号重复");
                            }else{
                                this.$message.error("操作失败");
                            }
                        })
                    }
                }
            });
            //this.adddeice = true;
        },
        addone(){
            this.deviceinfo.Rules.push({
                time:'',
                type:'1',
                price:'',
                people:''
            })
        },
        Edit(id){
            console.log(id);
            this.axios.get(constant.getdeviceinfo,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "USING"){
                    this.$message.error("该设备正在使用中或存在异常订单，需到订单管理中手动完结");
                }else{
                    this.deviceinfo = response.data;
                    if(!this.deviceinfo.GameMap)
                        this.deviceinfo.GameMap = [];
                    this.deviceinfo.Rules = response.data.DeviceRule;
                    if(this.deviceinfo.MerchantID){
                        this.GetStore(this.deviceinfo.MerchantID);
                        this.deviceinfo.StoreID = response.data.StoreID;
                    }
                    this.adddevice = true;
                }
                
            });
        },

        Unbind(id,index){
            if(this.tabledata[index].Status == 1){
                this.$message.error("该设备正在使用中，请停用之后再进行操作");
                return false;
            }
            this.axios.get(constant.unbinddevice,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id,
                    opid:this.opid,
                    role:this.role,
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.$message.success("操作成功");
                    this.$router.go(0);
                }
                if(response.data == "USING"){
                    this.$message.error("该设备正在使用中");
                }
            });
        },
        Disable(id){
            this.axios.get(constant.disabledevice,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id,
                    opid:this.opid,
                    role:this.role
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.$message.success("操作成功");
                    this.$router.go(0);
                }
                if(response.data == "USING"){
                    this.$message.error("该设备正在使用中，无法停用");
                    return false;
                }
            });
        },
        Enable(id){
            this.axios.get(constant.enabledevice,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id,
                    opid:this.opid,
                    role:this.role
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.$message.success("操作成功");
                    this.$router.go(0);
                }
                if(response.data == "USING"){
                    this.$message.error("该设备正在使用中");
                }
            });
        },
        formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        
    },
    components: {
        draggable,
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.opid = Cookies.get("id");
        this.role = Cookies.get("role");
        
        this.init();
        this.axios.get(constant.allmerchant,{
            headers:{
                "content-type":"application/json"
            }
        }).then((response)=>{
            console.log(response.data);
            this.merchantlist = response.data;
        });
    }
}
</script>
<style scoped>
.deviceinfo >>> .avatar-uploader-icon {
    font-size: 40px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
}
.deviceinfo >>> .avatar {
    width: 178px;
    display: block;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
}
</style>
<style lang="less" scoped>
.vue-draggable {
  display: flex;
  flex-wrap: wrap;

  .draggable-item {
    width: 148px;
    height: 148px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    .el-image {
      width: 100%;
      height: 100%;
    }
    .shadow {
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.3s;
      color: #fff;
      font-size: 20px;
      line-height: 20px;
      padding: 2px;
      cursor: pointer;
      border-bottom-left-radius: 6px;
    }
    &:hover {
      .shadow {
        opacity: 1;
      }
    }
  }
  &.hideShadow {
    .shadow {
      display: none;
    }
  }

  &.disabled {
    position: relative;

    .disabledShadow {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      background-color: rgba(255, 255, 255, 0.4);

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
</style>